<template>
  <div>
    <en-table-layout :tableData="tableData.data" :loading="loading" :tips="true">
      <!-- 搜索条件 -->
      <div slot="tips" class="TitleBox">
        <div class="conditions">
          <div>输入搜索：</div>
          <el-input
            v-model="params.activity_name"
            placeholder="请输入活动名称"
            class="ipt-default"
            style="width:210px;"
            size="mini"
          ></el-input>
        </div>
        <div class="conditions">
          <el-button
            @click="searchActivateList"
            type="primary"
            class="btn-default"
            style="margin-left:20px;"
            size="mini"
          >搜索</el-button>
        </div>
      </div>

      <template slot="table-columns">
        <!--活动名称-->
        <el-table-column label="活动名称" width="225">
          <template slot-scope="scope">
            <div class="imgBox">
              <img :src="scope.row.original" />
              <div :title="scope.row.activity_name">{{ scope.row.activity_name}}</div>
            </div>
          </template>
        </el-table-column>
        <!--活动一级分类-->
        <el-table-column prop="category_first" label="活动一级分类" />
        <!--活动二级分类--->
        <el-table-column prop="category_second" label="活动二级分类" />
        <!--原价-->
        <el-table-column label="原价">
          <template slot-scope="scope">
            <div>{{scope.row.mkt_low?scope.row.mkt_low:0}}元{{scope.row.mkt_low===scope.row.mkt_high?'':'起'}}</div>
          </template>
        </el-table-column>
        <!--销售价-->
        <el-table-column label="销售价">
          <template slot-scope="scope">
            <div>{{scope.row.price_low}}元{{scope.row.price_low===scope.row.price_top?'':'起'}}</div>
          </template>
        </el-table-column>
        <!--创建时间-->
        <el-table-column prop="create_date" :formatter="MixinUnixToDate" label="创建时间" />
        <!--操作-->
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <div class="buttonBox">
              <el-button
                @click="edit(scope.row)"
                type="text"
                size="mini"
                style="margin-bottom:5px;"
              >编辑</el-button>
              <el-button
                @click="openDialog('del',scope.row)"
                type="text"
                size="mini"
                style="margin-bottom:5px;margin-left:0;color:#fe5558;"
              >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="tableData.data"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
    <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="dialogContent">{{dialogContent}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_activityDdraft from "@/api/activityDraft";

export default {
  name: "activityList",
  data() {
    return {
      //  列表loading状态
      loading: false,
      //  列表参数
      params: {
        page_no: 1,
        page_size: 10,
        activity_name: "",
      },
      //  列表数据
      tableData: {},
      // 弹框显示内容
      dialogTitle: "",
      dialogContent: "",
      centerDialogVisible: false,

      // 选中的列表行
      chooseRow: "",
    };
  },
  mounted() {
    this.POST_activeDraftGoodsActivityShopPage();
  },
  activated() {
    this.POST_activeDraftGoodsActivityShopPage();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.POST_activeDraftGoodsActivityShopPage();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.POST_activeDraftGoodsActivityShopPage();
    },
    /** 查询活动草稿列表 */
    searchActivateList() {
      this.params.page_no = 1
      this.POST_activeDraftGoodsActivityShopPage()
    },

    /** 获取草稿列表 */
    POST_activeDraftGoodsActivityShopPage() {
      API_activityDdraft.activeDraftGoodsActivityShopPage(this.params).then(
        (res) => {
          this.tableData = res;
          console.log(res);
        }
      );
    },

    /** 打开弹窗 */
    openDialog(type, row) {
      this.centerDialogVisible = true;
      if (type === "del") {
        this.dialogTitle = "删除活动草稿";
        this.dialogContent = "删除活动草稿将无法找回，确认删除吗？";
        this.chooseRow = row;
      }
    },

    /** 弹窗点击确定 */
    ok() {
      if (this.dialogTitle === "删除活动草稿") {
        API_activityDdraft.activeDraftGoodsActivityDel(
          this.chooseRow.goods_id
        ).then((res) => {
          console.log(res);
          this.chooseRow.id = "";
          this.$message.success("删除活动草稿成功");
          this.POST_activeDraftGoodsActivityShopPage();
        });
      }
      this.centerDialogVisible = false;
    },

    /** 点击编辑按钮页面跳转 */
    edit(row) {
      this.$router.push({
        name: "活动详情",
        params: { id: row.goods_id, type: "draft" },
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
/deep/ .el-table td:not(.is-left) {
  text-align: center;
}

.TitleBox {
  display: flex;
  flex-wrap: wrap;
  .conditions {
    display: flex;
    align-items: center;
    div {
      font-size: 15px;
      font-weight: 500;
      color: rgba(68, 68, 68, 1);
    }
    margin-bottom: 20px;
    .choose-machine {
      width: 60%;
    }
  }
}

.imgBox {
  display: flex;
  align-items: center;
  img {
    width: 64px;
    height: 48px;
    margin-right: 10px;
  }
  div {
    width: 150px;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dialogContent {
  font-size: 16px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
/deep/ .toolbar {
  padding: 0 !important;
}
</style>
